import React from 'react';

export default function Header() {
  return (
    <div className="headerContainer">
      <div>
        <img className="cazvidLogo" src="https://cazvid.com/images/LOGO-CazVid-White.png" width="250" height="60" />
      </div>
    </div>
  )
}