import React from 'react';

import './styles.css';

export default function PromoContainer({language}) {

  let listItems = [];
  language == 'english' ? 
  listItems = ['Free to post videos', 'Free to search jobs', 'Free to search candidates', 'Free to contact'] : 
  listItems = ['Gratis para publicar videos', 'Gratis para buscar empleo', 'Gratis para buscar candidatos', 'Gratis para contactar']

  return (
    <div className="promoColumn-1">
      <div className="buttonContainer">
        <a href="https://cazvid.app.link" class="buttonApply">
          <p class="buttonText">Contact Now!</p>
          <p class="buttonSubText">Respond to this listing</p>
        </a>
      </div>
      <ul>
        <li>Free to post videos</li>
        <li>Free to search jobs</li>
        <li>Free to search candidates</li>
        <li>Free to contact</li>
      </ul>
      <div className="buttonContainer">
        <a href="https://cazvid.app.link" class="buttonApply">
          <p class="buttonText">¡Contacta Ahora!</p>
          <p class="buttonSubText">Responder a este listado</p>
        </a>
      </div>
      <ul>
        <li>Gratis para publicar videos</li>
        <li>Gratis para buscar empleo</li>
        <li>Gratis para buscar candidatos</li>
        <li>Gratis para contactar</li>
      </ul>
    </div>
    
  );
};