import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';

import LandingPage from './pages/LandingPage';
import Post from './pages/Post';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage/>} />
        <Route exact path="/:language" element={<LandingPage/>} />
        <Route exact path="/:language/videos" element={<Post/>} />
        <Route exact path="/:language/videos/:postType" element={<Post/>} />
        <Route path="/:language/videos/:postType/:postId" element={<Post/>} />
        <Route path="/:language/videos/:postType/:postAuthorId/:postId" element={<Post/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

