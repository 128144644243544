import React from 'react';

import './styles.css';

export default function StoreButtonsContainer() {
  return (
    <div>
      <a href="https://apps.apple.com/us/app/cazvid-app/id1532954837" target="_blank" rel="noopener">
        <img class="padding-5" width="150" src="https://cazvid.com/images/badge-app-store.png" alt="Download on the App Store" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.cazvid.cazvidapp" target="_blank" rel="noopener">
        <img class="padding-5" width="150" src="https://cazvid.com/images/badge-google-play.png" alt="Get it on Google Play"/>
      </a>
    </div>
  )
}