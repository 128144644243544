import React from 'react';

export default function({post}) {
  return (
    <div className="detailsContainer">
      <div className="titleContainer">
        <h2 className="listingTitle">{post?.title}</h2>
      </div>
      <div className="userContainer">
        <i class="fa-solid fa-user"></i>
        <h3 className="listingUserName">{post?.author.name}</h3>
      </div>
      <div className="locationContainer">
        <i class="fa-solid fa-location-dot"></i>
        <h4 className="listingLocation">{post?.location}</h4>
      </div>
      <p className="listingDescription">{post?.description}</p>
    </div>
  )
}