import React from 'react';
import Footer from '../../components/Footer';

import Header from '../../components/Header';
import MainPromo from '../../components/MainPromo';
import PromoContainer from '../../components/PromoContainer';
import StoreButtonsContainer from '../../components/StoreButtonsContainer';
import LoadingSpinner from '../../components/LoadingSpinner';

import './styles.css';

export default function LandingPage() {
  return (
    <>
      <div className="App">
        <Header />
        <MainPromo />
        <PromoContainer />
        <StoreButtonsContainer />
        <Footer />
      </div>
    </>
  );
};