import React from 'react';

import ReactPlayer from 'react-player'

import './styles.css';

export default function VideoPlayer({path}) {

  const extension = path.split('.')[3];

  console.log('extension', extension)
  return (
    <div className="videoContainer">
      {extension !== 'm3u8' ? (
        <video class="videoStyle" controls autoPlay >
          <source src={path} />
        </video>
      ) : (
        <ReactPlayer url={path} width={'360px'} height={'600px'} controls />
      )}
    </div>
  )
}

// poster={post?.post_thumbnail}