import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from "firebase/functions";


import './styles.css'
import PromoContainer from '../../components/PromoContainer';
import Header from '../../components/Header';
import PostHeader from '../../components/PostHeader';
import VideoPlayer from '../../components/VideoPlayer';
import StoreButtonsContainer from '../../components/StoreButtonsContainer';
import PostDetails from '../../components/PostDetails';
import Footer from '../../components/Footer';

const app = initializeApp({
  projectId: 'cazvid',
  apiKey: 'AIzaSyCUexeK9s3k-165LNR_Y5dMrGtUMe2VcZI',
  authDomain: 'cazvid.firebaseapp.com',
});

const functions = getFunctions();

export default function Post() {
  const [post, setPost] = useState();
  const { language, postId, postType } = useParams();
  const [postPath, setPostPath] = useState();

  useEffect(() => {
    getPostData({ postId: postId })
    .then((result) => {
      const data = result.data;
      const authorName = data._fieldsProto.author.mapValue.fields.name.stringValue;
      const description = data._fieldsProto.details.mapValue.fields.description.stringValue;
      const title = data._fieldsProto.details.mapValue.fields.title.stringValue;
      const location = data._fieldsProto.details.mapValue.fields.location.mapValue.fields.name.stringValue;
      const video = data._fieldsProto?.postVideo?.stringValue || data._fieldsProto?.videoStream?.stringValue;
      setPost({
        author: {
          name : authorName
        },
        description: description,
        title: title,
        location: location
      })
      setPostPath(video);
    });
  },[])

  const getPostData = httpsCallable(functions, 'getPostForWebApp');

  return (
    <div className="App">
      <Header />
      <div className="postContainer">
        <div className="column1Container">
          {postPath && (
            <VideoPlayer path={postPath} />
          )}
        </div>
        <div className='column2Container'>
          <PostDetails post={post} />
          <div class="promoColumn">
            <PromoContainer language={language} />
          </div>
          <StoreButtonsContainer />
        </div>
      </div>
      <Footer />
    </div>
  );
};